<template>
  <div class="tabbar-view">
    <div class="tabbar-view-main" >
      <div class="tabbar-view-main-item" v-for="(item,index) in arr" :key="item.id">
        <div class="tabbar-view-item-v" @click="clickTabBar(item.id)">
          <div class="tabbar-view-main-item-icon">
            <img :src="item.icon" class="tabbar-view-main-item-icon-img"/>
          </div>
          <div class="tabbar-view-main-item-title">
            {{item.name}}
          </div>
        </div>
        <div class="tabbar-view-main-d" v-if="index !== 3"/>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name:"tabbar",
  data(){
    return{}
  },
  props:{
    arr:Array,
    visible:{
      type:Boolean,
      default:false,
    }
  },
  methods:{
    clickTabBar(v){
      this.$emit("clickTab",v);
    }
  },
  mounted() {
    console.log(this.arr.pop(),"========")
  }
}
</script>
<style lang="less">
.tabbar-view{
  background: #FFFFFF;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  //padding: 53px;
  padding-top: 53px;
  padding-bottom: 53px;
  padding-right: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;

  &-main{
    display: flex;
    justify-content: space-between;
    align-items:baseline;
    width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
    white-space:nowrap;
    &::-webkit-scrollbar{// 滚动条整体
      background:none;
    }
    .tabbar-view-main-item{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      .tabbar-view-main-item-icon{
        width: 60px;
        height: 60px;
        //margin-right: 10px;
        //margin-top: -10px;
        //margin-left: 10px;
        .tabbar-view-main-item-icon-img{
          width: 100%;
          height: 100%;
        }
      }
      .tabbar-view-main-item-title{
        font-size: 40px;
        font-weight: 400;
        color: #242424;
      }
    }
  }
}
.tabbar-view-main-d{
  width: 2px;
  height: 52px;
  background: rgba(147, 147, 147, 0.9);
  //margin-right: 60px;
}
.tabbar-view-item-v{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100px;
}
.tabbar-view-item-v:active{
  background: rgba(233, 232, 237, 1);
}
</style>
